import * as React from "react";
import * as uuid from "uuid";
import { useState } from "react";
import { DarleneChoice } from "../darlene";
import { PaddingLeft } from "./utils/Padding";
import { Item } from "./Item";
import { useConfig } from "../providers/ConfigProvider";
import { Step } from "./Step";
import _ from "lodash";

export const Choice: React.FC<DarleneChoice> = (props: DarleneChoice) => {
	const [showItems, setShowItems] = useState(false);
	const [arrayIndex, setArrayIndex] = useState(0);
	const [config, { mapConfigValue, pushConfigArray, removeObjectFromArray, removeObject }] = useConfig();
	const [id] = useState(uuid.v4());

	function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
		setShowItems(event.target.checked);
		if (props.flat) {
			if (event.target.checked) {
				mapConfigValue?.(props.maps_to, { [props.maps_to]: {} });
			} else {
				removeObject?.(props.maps_to);
			}
			return;
		}

		if (!props.map_object) {
			if (event.target.checked) {
				const idx = pushConfigArray?.(props.root_maps_to, { [props.maps_to]: props.value }) ?? 0;
				setArrayIndex(idx);
			} else {
				removeObjectFromArray?.(props.root_maps_to, props.maps_to, props.value);
				setArrayIndex(-1);
			}
		} else {
			if (event.target.checked) {
				mapConfigValue?.(props.root_maps_to, { ..._.get(config, props.root_maps_to), ...{ [props.maps_to]: {} } });
			} else {
				removeObject?.(`${props.root_maps_to}.${props.maps_to}`);
			}
		}
	}
	function handleRadioChange() {
		setShowItems(true); // TODO: When the radio button becomes empty, showItems should be set to false
		// console.log(props);
		mapConfigValue?.(props.maps_to, props.value);
	}

	return (
		<>
			<div className="form-group w-400 d-flex flex-row align-items-center">
				<div className={props.multi ? "custom-checkbox" : "custom-radio"}>
					{
						props.multi
							? <input type="checkbox" onChange={handleCheckboxChange} id={id}/>
							: <input type="radio" name={props.name ?? props.maps_to ?? props.root_maps_to} onChange={handleRadioChange} id={id}/>
					}
					<label htmlFor={id}>{props.label}</label>
				</div>
			</div>
			<br/>
			{
				showItems && <>
					<PaddingLeft padding={30}>
						{props.items?.map(item =>
							<Item {...item} maps_to={
								props.flat ? item.maps_to :
								(!props.map_object ?
									`${props.root_maps_to}[${arrayIndex}].${item.maps_to}` :
									(
										!!props.root_maps_to ?
											`${props.root_maps_to}.${props.maps_to}.${item.maps_to}` :
											`${props.maps_to}.${item.maps_to}`
									))
							}/>) ?? []}
						{props.steps?.map(step => <Step root={step}/>)}
						{props.choices?.map(choice => <Choice {...choice} root_maps_to={`${props.root_maps_to}.${props.maps_to}`} maps_to={choice.maps_to}/>)}
					</PaddingLeft>
				</>
			}
		</>
	)
}
