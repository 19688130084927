import * as React from "react";
import * as uuid from "uuid";
import { useState } from "react";
import { useConfig } from "../../providers/ConfigProvider";

type SelectProps = {
	label: string;
	maps_to: string;
	options: string[];
}

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
	const [, { mapConfigValue }] = useConfig();
	const [selectId] = useState(uuid.v4());

	function setValue(value: any) {
		mapConfigValue?.(props.maps_to, value);
	}

	function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
		setValue(event.target.value);
	}

	return (
		<div className="form-group w-400">
			<label style={{ lineHeight: 1.5 }} htmlFor={selectId}>{props.label}</label>
			<select className="form-control" id={selectId} onChange={handleChange}>
				{props.options.map(opt => <option key={opt} value={opt}>{opt}</option>)}
			</select>
		</div>
	);
}
