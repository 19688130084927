import * as React from "react";
import { useConfig } from "src/providers/ConfigProvider";
import { DarleneStep } from "src/darlene";
import { useState } from "react";
import * as uuid from "uuid";

type RadioProps = {
	maps_to: string;
	label?: string;
	value?: string;
	description?: string;
	steps?: DarleneStep[];
	name?: string;
	level?: number;
};

export const Radio: React.FC<RadioProps> = (props: RadioProps) => {
	const [, { mapConfigValue }] = useConfig();
	const [radioId] = useState(`radio_${uuid.v4()}`);

	function handleChange() {
		mapConfigValue?.(props.maps_to, props.value);
	}

	return (
		<div className="w-400 d-flex flex-row align-items-center form-group">
			<div className="custom-radio">
				<input type="radio" value={props.value} onClick={handleChange} name={props.maps_to ?? props.name} id={radioId}/>
				<label style={{ lineHeight: 1.5 }} htmlFor={radioId}>{props.label}</label>
			</div>
		</div>
	)
}
