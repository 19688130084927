import * as React from "react";
import { useConfig } from "src/providers/ConfigProvider";

type InputProps = {
	maps_to: string;
	label?: string;
	placeholder?: string;
	array_item?: boolean;
	map_object?: boolean;
	value?: any;
	is_text?: boolean;
};

export const Input: React.FC<InputProps> = (props: InputProps) => {
	const [, { mapConfigValue, pushConfigArray }] = useConfig();

	function setValue(value: any) {
		const v = props.map_object ? { [props.maps_to]: value } : value;
		props.array_item
			? pushConfigArray?.(props.maps_to, v)
			: mapConfigValue?.(props.maps_to, v);
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setValue(event.target.value);
	}

	props.value && setValue(props.value);

	return (
		<div className="w-400 mw-full">
			<div className="form-group">
				<label style={{ lineHeight: 1.5 }}>{props.label}</label>
				<input type={props.is_text ? "text" : "number"} className="form-control" onChange={handleChange} defaultValue={props.value} placeholder={props.placeholder}/>
			</div>
		</div>
	)
}
