import * as React from "react";
import { Input } from "./items/Input";
import { DarleneItem } from "src/darlene";
import { Checkbox } from "./items/Checkbox";
import { Radio } from "./items/Radio";
import { Select } from "./items/Select";

const ItemsKindMap: Record<string, React.FC<any>> = {
	"INPUT": Input,
	"CHECKBOX": Checkbox,
	"RADIO": Radio,
	"SELECT": Select,
}

export const Item: React.FC<DarleneItem> = (props: DarleneItem) => {
	if (!props.kind) {
		console.error("Missing kind in Item component", props);
		return <></>
	}

	const Component = ItemsKindMap[props.kind];
	if (!Component) {
		return <span>Component not found: {props.kind}</span>
	}

	return <Component {...props}/>
}
