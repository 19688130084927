import * as React from "react";
import { useContext, useState } from "react";
import { BaseProps } from "src/types";
import { stringify } from "yaml";
import _ from "lodash";

type FunctionMapConfigValue = ((path: string, value: any) => void) | null;
type FunctionPushConfigArray = ((path: string, value: any) => number) | null;
type FunctionRemoveValueFromArray = ((path: string, value: any) => void) | null;
type FunctionRemoveObjectFromArray = ((path: string, key: string, value: any) => void) | null;
type FunctionRemoveObject = ((path: string) => void) | null;
type FunctionGenerateYAML = (() => void) | null;
type FunctionDownloadYAML = ((yaml: string) => void) | null;

type Config = Record<string, any>;
type ConfigFunctions = {
	mapConfigValue: FunctionMapConfigValue;
	pushConfigArray: FunctionPushConfigArray;
	removeValueFromArray: FunctionRemoveValueFromArray;
	removeObjectFromArray: FunctionRemoveObjectFromArray;
	removeObject: FunctionRemoveObject;
	generateYAML: FunctionGenerateYAML;
	downloadYAML: FunctionDownloadYAML;
}

type ConfigContextDescriptor = [ Config, ConfigFunctions ];
const ConfigContext: React.Context<ConfigContextDescriptor> = React.createContext<ConfigContextDescriptor>([{}, {
	mapConfigValue: null,
	pushConfigArray: null,
	removeValueFromArray: null,
	removeObjectFromArray: null,
	removeObject: null,
	generateYAML: null,
	downloadYAML: null,
}]);

export const useConfig: () => ConfigContextDescriptor = () => {
	const context = useContext(ConfigContext);
	if (!context) {
		throw new Error("useConfig can only be used inside ConfigProvider");
	}
	return context;
}

export const ConfigProvider: React.FC<BaseProps> = (props: BaseProps) => {
	const [config, setConfig] = useState<Config>({});

	function removeEmptyObjects(obj: any) {
		return _(obj)
			.pickBy(_.isObject)
			.mapValues(removeEmptyObjects)
			.omitBy(_.isEmpty)
			.assign(_.omitBy(obj, _.isObject))
			.value();
	}

	function mapConfigValue(path: string, value: any): void {
		if (value === null || value === "") {
			return removeObject(path);
		}

		setConfig(
			_.set(config, path, value)
		);
	}
	function pushConfigArray(path: string, value: any): number {
		setConfig(
			_.set(config, path, [ ..._.get(config, path) ?? [], value ])
		);
		return _.get(config, path).length - 1;
	}
	function removeValueFromArray(path: string, value: any): void {
		setConfig(
			_.set(config, path, _.filter(_.get(config, path) ?? [], c => c !== value))
		);
	}
	function removeObjectFromArray(path: string, key: string, value: any): void {
		setConfig(
			_.set(config, path, _.filter(_.get(config, path) ?? [], c => c[key] !== value))
		);
	}
	function removeObject(path: string): void {
		_.unset(config, path);
		setConfig(config);
	}

	function generateYAML(): string {
		const cfg = removeEmptyObjects(config);
		console.log("JSON Config is: ", cfg);
		return stringify({ experiment: cfg });
	}
	function downloadYAML(yaml: string) {
		console.log(yaml);
		const a = document.createElement("a");
		a.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(yaml));
		a.setAttribute("download", "elliot_configuration.yaml");
		a.click();
	}

	const data: ConfigContextDescriptor = [config, {
		mapConfigValue, pushConfigArray, removeValueFromArray,
		removeObjectFromArray, removeObject,
		generateYAML, downloadYAML
	}];
	return (
		<ConfigContext.Provider value={data}>
			{props.children}
		</ConfigContext.Provider>
	)
}
