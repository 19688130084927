import * as React from "react";
import { DarleneStep } from "src/darlene";
import { Title } from "./utils/Title";
import { Description } from "./utils/Description";
import { Item } from "./Item";
import { Section } from "./Section";
import { Choice } from "./Choice";
import { StepWizardChildProps } from "react-step-wizard";
import { useConfig } from "../providers/ConfigProvider";

export const Step: React.FC<Partial<StepWizardChildProps> & { root: DarleneStep | unknown }> = (props: Partial<StepWizardChildProps> & { root: DarleneStep | unknown }) => {
	const root = props.root as DarleneStep;
	const [, { generateYAML, downloadYAML }] = useConfig();

	// TODO: At the moment, this component does not get re-rendered when the config changes...
	// function checkDeps(path: string, value: any): boolean {
	// 	return _.get(config, path) === value;
	// }
	//
	// if (!!root.dependencies && !Object.keys(root.dependencies).every(dep => checkDeps(dep, root.dependencies?.[dep]))) {
	// 	// Skip this step, dependencies not met
	// 	return <></>;
	// }

	function onLastStep() {
		const yaml = generateYAML?.() ?? "";
		downloadYAML?.(yaml);
	}

	return (
		<div className="w-full d-flex align-items-center justify-content-center flex-column">
			<Title>{root.title}</Title>
			<Description>{root.description}</Description>
			<div style={{ marginTop: 20 }} className="d-flex align-items-center justify-content-center flex-column">
				{root.sections?.map(section => <Section {...section}/>)}
				{root.items?.map(item => <Item {...item}/>)}
				{root.choices?.map(choice => <Choice {...choice} multi={root.multi} root_maps_to={root.maps_to as string}/>)}
				<div style={{ marginTop: 20 }} >
					{ props.currentStep !== 1 && <button className="btn" onClick={props.previousStep}>Back</button> }
					<button style={{ marginLeft: 20 }} className="btn btn-primary" onClick={props.currentStep === props.totalSteps ? onLastStep : props.nextStep}>{
						props.currentStep === 1 ? "Start" : (props.currentStep === props.totalSteps ? "Generate YAML" : "Continue")
					}</button>
				</div>
			</div>
		</div>
	)
}
