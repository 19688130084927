import * as React from "react";
import { Step } from "src/components/Step";
import { ConfigProvider } from "src/providers/ConfigProvider";
import { DarleneStep } from "./darlene";
import StepWizard from "react-step-wizard";

export const App: React.FC<{ root: DarleneStep }> = (props: { root: DarleneStep }) => {
	const { root } = props;
	const transitions = {
		enterRight: "",
		enterLeft : "",
		exitRight : "",
		exitLeft  : "",
	}

	const flatDeepByKey = (data: any, key: any) => {
		return data.reduce((prev: any, el: any) => {
			prev.push(el);
			if (el[key]) {
				prev.push(...flatDeepByKey(el[key], key))
			}
			return prev;
		}, []);
	};

	// Flatten steps and render them in a single comprehensive wizard
	const steps = flatDeepByKey([ root ], "steps");
	return (
		<ConfigProvider>
			<StepWizard transitions={transitions}>
				{steps.map((step: any) => <Step root={step}/>)}
			</StepWizard>
		</ConfigProvider>
	)
}
