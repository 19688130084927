import * as React from "react";
import * as uuid from "uuid";
import { useConfig } from "src/providers/ConfigProvider";
import { useState } from "react";

type CheckboxProps = {
	maps_to: string;
	label?: string;
	tooltip?: string;
	array_item?: boolean;
	value?: string;
	checked?: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
	const [, { mapConfigValue, pushConfigArray, removeValueFromArray }] = useConfig();
	const [checkboxId] = useState(uuid.v4());

	function setBooleanValue(bool: boolean) {
		if (bool) {
			props.array_item
				? pushConfigArray?.(props.maps_to, props.value)
				: mapConfigValue?.(props.maps_to, props.value);
		} else {
			props.array_item
				? removeValueFromArray?.(props.maps_to, props.value)
				: mapConfigValue?.(props.maps_to, false);
		}
	}

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		setBooleanValue(event.target.checked);
	}

	props.checked && setBooleanValue(true);

	return (
		<div className="form-group w-400 d-flex flex-row align-items-center">
			<div className="custom-checkbox">
				<input type="checkbox" defaultChecked={props.checked} id={checkboxId} onChange={handleChange}/>
				<label htmlFor={checkboxId}>{props.label}</label>
			</div>
		</div>
	)
}
