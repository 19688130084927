import React from "react";
import ReactDOM from "react-dom";
import "halfmoon/css/halfmoon-variables.min.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import rootSchema from './schema.json';
import _ from "lodash";
import { App } from './App';

function resolveSchemaIncludes(schema: any, includes: any) {
	[ ...schema.steps ?? [], ...schema.sections ?? [], ...schema.choices ?? [] ].forEach(
		(e: any) => resolveSchemaIncludes(e, includes)
	);
	schema.items = _.flatten(schema.items?.map((item: any) => item.include ? includes[item.include] : item) ?? []);
}

resolveSchemaIncludes(rootSchema, rootSchema.includes);
console.log(rootSchema);
ReactDOM.render(
	<React.StrictMode>
		<App root={rootSchema as any}/>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
