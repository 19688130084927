import * as React from "react";
import { DarleneSection } from "../darlene";
import { Item } from "./Item";
import { Choice } from "./Choice";

export const Section: React.FC<DarleneSection> = (props: DarleneSection) => {
	return (
		<details className="collapse-panel w-600 mw-full m-10">
			<summary className="collapse-header">{props.title}</summary>
			<div className="collapse-content">
				{props.choices?.map(choice =>
					<Choice {...choice} multi={true} root_maps_to={props.maps_to}/>
				)}
				{props.items?.map(item => <Item {...item}/>)}
			</div>
		</details>
	)
}
